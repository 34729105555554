import React, { useEffect, useState } from "react"
import {
  CameraOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import { Collapse } from "antd"

import copy from "../../../assets/images/copyy.png"

const { Panel } = Collapse

export const SectionTutorialThankYou = ({
  titlePhotos,
  photoSuccess,
  photoFailed,
  documentationRequired,
  documentationsItems,
}) => {
  const [documentation, setDocumentation] = useState()

  useEffect(() => {
    if (documentationRequired.description && documentationRequired.metada) {
      let newDescription = documentationRequired.description.description
      documentationRequired.metada.map((item, i) => {
        newDescription = newDescription.replace(
          `{email${i + 1}}`,
          `<a>${item.internal.content.replace(/"/g, "")}</a>`
        )
        return item
      })
      setDocumentation(newDescription)
    }
  }, [])

  return (
    <div className="SectionTutorialThankYou">
      <div className="SectionTutorialThankYou__conatiner">
        <h1 className="SectionTutorialThankYou__conatiner--title">
          <CameraOutlined className="icon-camera" />
          {titlePhotos}
        </h1>
        <div
          className="SectionTutorialThankYou__conatiner__steps"
          data-aos="fade-up"
        >
          <div className="cont-card">
            <div
              className="step step--well"
              style={{ backgroundImage: `url(${photoSuccess.image})` }}
            >
              {/* <CheckCircleOutlined className="well" /> */}
            </div>
            <p className="description">
              {photoSuccess.description?.description}
            </p>
          </div>
          <div className="cont-card">
            <div
              className="step step--wrong"
              style={{ backgroundImage: `url(${photoFailed.image})` }}
            >
              {/* <CloseCircleOutlined className="wrong" /> */}
            </div>
            <p className="description">
              {photoFailed.description?.description}
            </p>
          </div>
        </div>
        <div className="SectionTutorialThankYou__conatiner__documentation">
          <div className="title">
            <img src={copy} alt="copy" className="copy" />
            {documentationRequired.title}
          </div>
          <p
            className="descrip"
            dangerouslySetInnerHTML={{ __html: documentation }}
          />

          <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
            {documentationsItems?.map((document, index) => (
              <Panel
                header={`${document.name} (${document.links?.length})`}
                key={index + 1}
              >
                {document.links?.map((link, i) => (
                  <ul key={i}>
                    <li className="item">{link.label}</li>
                  </ul>
                ))}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  )
}
