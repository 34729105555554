import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Input } from "antd"
import ReactPlayer from "react-player"
import { DownOutlined, CloseOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { SectionTutorialThankYou } from "../../components/PageLiftersThankYou/SectionTutorialThankYou/SectionTutorialThankYou"
import { liftersView as LiftersViewActions } from "../../services/LiftersView/LiftersViewActions"
import { hubspot as HubspotActions } from "../../services/Hubspot/HubspotActions"
import clock from "../../assets/images/clock.png"
import { addSpreedSheet } from "../../@common/services/googleSheets"
import { FORMS_LIFTERS } from "../../@common/constansts/googleSheets"
import { formKeysGoogleSheets } from '../../components/PageLifters/FormNewLead/Steps/components/FormPersonNatural/FormPersonNatural'
import { navigatePage } from "../../@common/utils/navigate"

const LiftersThankYou = ({ data: { contentfulPaginaThanksYouLifters } }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if (!contentfulPaginaThanksYouLifters) return null

  const {
    thanks_interest,
    ready_larry,
    section_2_title,
    photo_success,
    photo_failed,
    documentation_required,
    documentations,
  } = contentfulPaginaThanksYouLifters

  const [visible, setVisible] = useState(true)
  const [changes, setChanges] = useState(false)
  const { newLead } = useSelector(state => state.liftersView)
  const { countries } = useSelector(state => state.poetri)

  useEffect(() => {
    if(!newLead)
      navigatePage("/lifters")
  }, [])

  const handlerChangeEmail = async () => {
    const newEmail = document.getElementById('email').value
    dispatch(LiftersViewActions.setNewLead({ ...newLead, email: newEmail }))

    const params = { ...newLead, email: newEmail }
    const paramsGoogleSheets = {}
    Object.keys(params).map(key => {
      if (formKeysGoogleSheets[key])
        paramsGoogleSheets[formKeysGoogleSheets[key]] = params[key]
    })

    let indexCountry = 0

    countries.find((country, index) => {
      if (country.alpha2code === newLead.prefix) {
        indexCountry = index
        return country
      }
    })

    if (newLead.type_person === t("general.natural"))
      await addSpreedSheet(
        FORMS_LIFTERS.spreadsheetId,
        paramsGoogleSheets,
        indexCountry,
        sessionStorage.getItem('lifters_row')
      )
    /*dispatch(HubspotActions.sendForm(
      HUBSPOT.FORMS_NEW_LEAD_NATURAL.id,
      HUBSPOT.FORMS_NEW_LEAD_NATURAL.keys,
      { ...newLead, email: newEmail }
    ))*/
    else
      dispatch(HubspotActions.sendForm(
        HUBSPOT.FORMS_NEW_LEAD_JURIDICAL.id,
        HUBSPOT.FORMS_NEW_LEAD_JURIDICAL.keys,
        { ...newLead, email: newEmail }
      ))

    setChanges(false)
  }

  return (
    <Layout>
      <div className="LiftersThankYou">
        <div className="LiftersThankYou--container">
          <div
            className={`LiftersThankYou--container--cont-video ${!visible && "spam"
              }`}
          >
            <h1>{thanks_interest.title}</h1>
            <p>{thanks_interest.description?.description}</p>
            <ReactPlayer
              url={thanks_interest.image}
              className="video-demo"
              playing={false}
              config={{
                youtube: {
                  playerVars: {
                    controls: 0,
                    fs: 0,
                    modestbranding: 0,
                  },
                },
              }}
            />
            <h4>{t("button.see_most_tips")}</h4>
            <DownOutlined className="icon" />
          </div>
          <div
            className={`LiftersThankYou--container--cont-mod ${!visible && "no-visible"
              }`}
          >
            <div
              className={`modal-emer ${!visible && "animate__animated animate__fadeOutRight"
                }`}
            >
              <div className="content-close">
                <Button className="close" onClick={() => setVisible(false)}>
                  <CloseOutlined className="close--icon" />
                </Button>
              </div>
              <div className="cont-text">
                <img className="clock" alt="clock" src={clock} />
                <h5 className="ready">{ready_larry.name}</h5>
                <h1 className="title">{ready_larry.title}</h1>
                <p className="text">{ready_larry.description?.description}</p>
                <h6 className="text-two">
                  {t("lifters_thanksyou.information_send_email")}{" "}
                  {newLead?.email}
                </h6>
                <Button onClick={() => setChanges(!changes)} className="more">{t("question.want_to_change")}</Button>
                {changes &&
                  <div>
                    <Input id="email" type="email" placeholder={t('general.email')} className="email-text" />
                    <div className="cont-btn cont-two">
                      <Button className="btn btn--primary" onClick={handlerChangeEmail}>
                        {t('button.to_accept')}
                      </Button>
                    </div>
                  </div>
                }
                <div className="cont-btn">
                  <Button className="btn btn--primary">
                    {t("button.read_recomendation")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SectionTutorialThankYou
          titlePhotos={section_2_title}
          photoSuccess={photo_success}
          photoFailed={photo_failed}
          documentationRequired={documentation_required}
          documentationsItems={documentations}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageThanksYouLifters($locale: String) {
    contentfulPaginaThanksYouLifters(language: { language: { eq: $locale } }) {
      thanks_interest {
        ...SectionFragment
      }
      ready_larry {
        ...SectionFragment
      }
      section_2_title
      photo_failed {
        ...SectionFragment
      }
      photo_success {
        ...SectionFragment
      }
      documentation_required {
        ...SectionFragment
      }
      documentations {
        ...FieldFragment
      }
    }
  }
`

export default LiftersThankYou
